import React from "react";
import { useNavigate } from "react-router-dom";

const Profile = ( { isLoggedIn } ) => {
  const navigate = useNavigate();
    
  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  return (
    <div>
    </div>
  );
};

export default Profile;