import React from "react";
import { useState } from "react";
import './login.css';
import { Link, useNavigate  } from "react-router-dom";
import axios from "axios";
import google from  '../../assets/google-icon-logo.svg';

const Login = ( { isLoggedIn, onLogin } ) => {
  const [formData, setFormData] = useState({email: "", password: ""});
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  
  if (isLoggedIn) {
    navigate('/feed');
    return ;
  }

  const handleChange = (event) => {
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message

    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message
        
    // Frontend validation
    const isEmailValid = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(formData.email);
    if (!formData.email || !isEmailValid) {
      setEmailError('⚠️ Please enter a valid email');
      return;
    }
    // Frontend validation
    if (formData.password.length < 7) {
      setPasswordError('⚠️ Password must be atleast 8 characters long');
      return;
    }

    try {
        await axios.post('api/v1/login', formData);
        //event.target.reset();

        onLogin();
        navigate('/feed');
    } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
        } else {
            console.log('An error occurred on log in. Please try again');
            setError('⚠️ An error occurred on log in. Please try again');
        }
    }
  };
  
  const handleGoogleSubmit = async (event) => {
    event.preventDefault();
    
    // Redirect the user to the Google OAuth endpoint
    window.location.href = '/api/v1/auth/google';
  }

  return (
    <div className="login__form section__padding gradient__bg" id="login">
      <div className="login__form-content">
        <h1 className="gradient__text">Log in to SastiRide</h1>
        <p>Explore rides.</p>
        
        <form onSubmit={handleSubmit} className="login__form-content__input">        
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email"/>
          {emailError && <label htmlFor="email">{emailError}</label>}
          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password"/>
          {passwordError && <label htmlFor="password">{passwordError}</label>}
          
          {/* Display error or success message */}
          {error && <label>{error}</label>}
          <button type="submit">Log in</button>
          <div className="login__form-content__input-separator"><p>or</p></div>
          <Link to="/feed" onClick={handleGoogleSubmit}>
            <div className="login__form-content__input-google__logo">
              <img src={google} alt="logo" />Log in with Google
            </div>
          </Link>

          <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>
        </form>
      </div>
    </div>
  );
}

export default Login;