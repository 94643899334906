import React from "react";
import './feed.css';
import { useState } from "react";
import  Field from "../../components/field/Field";
import { Link, useNavigate } from "react-router-dom";

const Feed = ( {isLoggedIn} ) => {
  const [formData, setFormData] = useState({departure: "", arrival: "", date: Date.now() });
  const navigate = useNavigate();
  
  if (!isLoggedIn) {
    navigate('/');
    return;
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`Departure: ${formData.departure}, Arrival: ${formData.arrival}, Date: ${formData.date}`);
  };

  const feedDetails = [ 
    {
      arrival: "mmmanhattan",
      departure: "newyork",    
      date: "2021-01-06",
      totalSeats: 0
    },
    {
      arrival: "london",
      departure: "paris",    
      date: "2024-09-12",
      totalSeats: 2
    }, 
    {
      arrival: "boston",
      departure: "bayarea",    
      date: "2022-04-11",
      totalSeats: 5
    },
    {
      arrival: "paris",
      departure: "istanbul",    
      date: "2019-04-11",
      totalSeats: 1
    },
    {
      arrival: "basphorous",
      departure: "baltic",    
      date: "2023-11-08",
      totalSeats: 3
    },
  ];

  return (
    <div className="gpt3__feed section__padding">
      <div className="feed__form">
        <div className="feed__form-content">
          <h1 className="gradient__text">Ride Finder</h1>
          <p>Select one or more filters and press 'Find a Ride'</p>

          <form onSubmit={handleSubmit} className="feed__form-content__input">
            <input type="text" id="departure" name="departure" value={formData.departure} onChange={handleChange} placeholder="Departure"/>
            <input type="text" id="arrival" name="arrival" value={formData.arrival} onChange={handleChange} placeholder="Arrival"/>
            <input type="date" id="date" name="date" value={formData.date} onChange={handleChange} placeholder="Date"/>
          
            <button type="submit">Find</button>
          </form>
          <p>Need to reset filters? <Link to="/feed">Click here</Link></p>
        </div>
      </div>
      
      <div className="feed__container">      
        <h2 className="gradient__text">Most Recent</h2>

        <div className="feed__container-content">
        {
          feedDetails.map((element, idx) => {
            const { arrival, departure, date, totalSeats} = element;
            
            return (
              <div className="feed__container-content__post">
                <Field heading="Arrival" text={arrival}></Field>
                <Field heading="Departure" text={departure}></Field>
                <Field heading="Date" text={date}></Field>
                <Field heading="Available Seats" text={totalSeats}></Field>
              </div>
            );
          })
        }      
        </div>
      </div>
    </div>
  );
}

export default Feed;