import React from "react";
import './cta.css';

const CTA = () => {
  return (
    <div className="gpt3__cta section__padding">
      <div className="gpt3__cta-content">
        <h3>Register today & start exploring better commute options and reach your desired destination.</h3>
      </div>
      <div className="gpt3__cta-button">
        <button>Sign Up</button>
      </div>
    </div>
  );
}

export default CTA;