import React from "react";
import Field from "../field/Field";
import './ride.css';
import lady from '../../assets/lady.jpg';
import { Link, useNavigate } from "react-router-dom";

const Ride = ( { isLoggedIn } ) => {
  const navigate = useNavigate();

  if (!isLoggedIn) {
    navigate('/');
    return;
  }
  
  const rideDetails = { 
    arrival: "manhattan",
    departure: "newyork",
    date: "2024-09-12",
    time: "1700",
    isStarted: false,
    totalSeats: 2,
    userName: "bob",
    /*userImage: "../../assets/lady.jpg"*/
   };

  const { arrival, departure, date, time, isStarted, totalSeats, userName } = rideDetails;
  
  return (
    <div className="ride__details section__padding gradient__bg" id="ride">
      <div className="ride__details-heading">
        <h1 className="gradient__text">Ride Detail</h1>
      </div>    
      
      <div className="ride__details-content">
        <img src={lady} alt="" href="/profile"/>
        <Field heading="Arrival" text={arrival}></Field>
        <Field heading="Departure" text={departure}></Field>
        <Field heading="Date" text={date}></Field>
        <Field heading="Time" text={time}></Field>
        <Field heading="Status" text={isStarted ? 'Not Available' : 'Available'}></Field>
        <Field heading="Total Seats" text={totalSeats}></Field>
        <Link to="/profile"><Field heading="Posted by" text={userName}></Field></Link>
        <button>Book Ride</button>     
      </div>
    </div>
  );
}

export default Ride;