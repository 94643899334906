import React from "react";
import { Link } from "react-router-dom";
import './footer.css';
import logo from '../../assets/sastiride-logo-white.svg';

const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
      {/*
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Do you want to step in to the future before others</h1>
      </div>

      <div className="gpt3__footer-button">
        <p>Request Early Access</p>
      </div>
      */}
      

      <div className="gpt3__footer-content">
        <div className="gpt3__footer-content__logo">
          <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>

        <div className="gpt3__footer-content__div">
          <h4 className="gradient__text">Links</h4>
          <p><Link to="/">Home</Link></p>
          <p><Link to="/feed">Feed</Link></p>
          <p><Link to="/profile">Profile</Link></p>
        </div>
        
        {/*
        <div className="gpt3__footer-content__div">
          <h4>Company</h4>
          <p><a href="#home">Terms & Conditions</a></p>
          <p><a href="#home">Privacy Policy</a></p>
          <p><a href="#home">Contact</a></p>
        </div>  
        */}

        <div className="gpt3__footer-content__div">
          <h4 className="gradient__text">Get in touch</h4>
          <p><a href="mailto:contact@sastiride.com">contact@sastiride.com</a></p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        <p>© 2024 SastiRide. All rights reserved.</p>
      </div>
    </div>
  );
}

export default Footer;