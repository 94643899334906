import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import './signup.css';
import google from  '../../assets/google-icon-logo.svg';

const Signup = ( { isLoggedIn, onLogin } ) => {
  const [formData, setFormData] = useState({userName: "", email: "", password: "", confirmPassword: "", phoneNumber: "", gender: 1});
  //const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  
  if (isLoggedIn) {
    navigate('/feed');
    return;
  }

  const handleChange = (event) => {
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message

    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Reset error message
    setEmailError(''); // Reset error message
    setPasswordError(''); // Reset error message
    
    // Frontend validation
    if (!formData.userName || !formData.email || !formData.password || !formData.confirmPassword || !formData.phoneNumber) {
      setError('⚠️ All fields are required!.');
      return;
    }
    
    // Frontend validation
    const isEmailValid = /^[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}$/.test(formData.email);
    if (!formData.email || !isEmailValid) {
      setEmailError('⚠️ Please enter a valid email');
      return;
    }
    // Frontend validation
    if (formData.password.length < 7) {
      setPasswordError('⚠️ Password must be atleast 8 characters long');
      return;
    }
    // Frontend validation
    if (formData.password !== formData.confirmPassword) {
      setPasswordError('⚠️ Password did not match');
      return;
    }

    try {
        await axios.post('api/v1/signup', formData);
        event.target.reset();
        
        navigate('/feed'); // Redirect to feed page after signup
    } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
        } else {
            setError('⚠️ An error occurred on Signup. Please try again');
        }
    }
  };

  const handleGoogleSubmit = async (event) => {
    event.preventDefault();

    setError(''); // Reset error message
    //setSuccess(''); // Reset error message

    try {
        axios.get('/api/v1/auth/google');
        navigate('/feed');
        onLogin();
    } catch (error) {
        if (error.response) {
          setError(error.response.data.message);
        } else {
            console.log('An error occurred on sign up with Google. Please try again');
            setError('⚠️ An error occurred on sign up with Google. Please try again');
        }
    }
  }

  return (
    <div className="signup__form section__padding gradient__bg" id="signup">
      <div className="signup__form-content">
        <h1 className="gradient__text">Sign Up with SastiRide</h1>
        <p>It's quick and easy.</p>
        
        <form onSubmit={handleSubmit} className="signup__form-content__input">
          <input type="text" id="userName" name="userName" value={formData.userName} onChange={handleChange} placeholder="User Name"/>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} placeholder="Email"/>
          {emailError && <label htmlFor="email">{emailError}</label>}
          <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password"/>
          {passwordError && <label htmlFor="password">{passwordError}</label>}
          <input type="password" id="confirmPassword" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} placeholder="Re-enter password"/>
          {passwordError && <label htmlFor="password">{passwordError}</label>}
          <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Phone number"/>
          
          <select id="gender" name="gender" value={formData.selectedValue} onChange={handleChange}>
            <option value="female">Female</option>
            <option value="male">Male</option>
            <option value="custom">Custom</option>
          </select>

          {/* Display error or success message */}
          {error && <label>{error}</label>}

          <button type="submit">Sign Up</button>
          <div className="signup__form-content__input-separator"><p>or</p></div>
          <Link to="/feed" onClick={handleGoogleSubmit}>
            <div className="signup__form-content__input-google__logo">
              <img src={google} alt="logo" />Sign up with Google
            </div>
          </Link>
          <p>Already have an account? <Link to="/login">Log in</Link></p>
        </form>        
      </div>
    </div>
  );
}

export default Signup;