import React, { useState } from "react";
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/sastiride-logo-white.svg';
import './navbar.css';
import axios from "axios";

const Navbar = ( {isLoggedIn, onLogout } ) => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const navigate = useNavigate();
  
  const onButtonClick = async () => {    
    try {
      await axios.post('api/v1/logout');
      onLogout();
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.message);
      } else {
        console.log('An error occurred on Logout. Please try again.');
      }
    }  
  };  

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <Link to="/"><img src={logo} alt="logo" /></Link>
        </div>
        <div className="gpt3__navbar-links_container">
          <p><Link to="/">Home</Link></p>
          {isLoggedIn && <p><Link to="/feed">Feed</Link></p>}
          {isLoggedIn && <p><Link to="/profile">Profile</Link></p>} 
          {/*
          <p><a href="#possibility">Open AI</a></p>
          <p><a href="#features">Case Studies</a></p>
          <p><a href="#blog">Library</a></p>
          */}
        </div>      
      </div>
      {isLoggedIn
      ? <div className="gpt3__navbar-sign">
          <button type="button" onClick={onButtonClick}><Link to="/login">Log out</Link></button>
        </div>
      : <div className="gpt3__navbar-sign">
          <p><Link to="/login">Sign in</Link></p>
          <button type="button"><Link to="/signup">Sign up</Link></button>
        </div>
      }
      <div className="gpt3__navbar-menu">
        { toggleMenu
          ? <RiCloseLine color="#fff" size={12} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={12} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
        <div className="gpt3__navbar-menu_container scale-up-center">
          <div className="gpt3__navbar-menu_container-links">
            <p><Link to="/">Home</Link></p>
            {isLoggedIn && <p><Link to="/feed">Feed</Link></p>}
            {isLoggedIn && <p><Link to="/profile">Profile</Link></p>}
            {/*
            <p><a href="#possibility">Open AI</a></p>
            <p><a href="#features">Case Studies</a></p>
            <p><a href="#blog">Library</a></p>
            */}
          </div>
          {isLoggedIn
          ? <div className="gpt3__navbar-sign">
              <button type="button" onClick={onButtonClick}><Link to="/login">Log out</Link></button>
            </div>
          : <div className="gpt3__navbar-sign">
              <p><Link to="/login">Sign in</Link></p>
              <button type="button"><Link to="/signup">Sign up</Link></button>
            </div>
          }
        </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;