import React from "react";
import './whatGPT3.css';
import Feature from "../../components/feature/Feature";

const WhatGPT3 = () => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
      <div className="gpt3__whatgpt3-feature">
        <Feature title="What is SastiRide" text="Do you feel frustrated for scrolling through 100s of messages in Whatsapp group for ride confirmation? Do you feel FOMO for a good ride offer on Fridays and Sundays? Do you want to see the authenticity of ride provider?"></Feature>
      </div>
      
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">Safe rides are not beyond your reach, anymore.</h1>
        <p>Explore The Library</p>
      </div>
      
      <div className="gpt3__whatgpt3-container">
      <Feature title="Easy and Efficient" text="SastiRide offers easy-to-use features to lessen the burden of ride searching. Unlike other platform, its filters ride as per user's choice"></Feature>
      <Feature title="Better Options" text="With a dedicated page for rides, user can see the profile of ride poster with record of previous rides, their ranking, cancellation rates and many more."></Feature>
      <Feature title="Increased Authenticity" text="To overcome the problems of direct contact through potential dangerous means, SastiRide offers a credible mean to connect with user verification."></Feature>
      </div>
        
    </div>
  );
}

export default WhatGPT3;