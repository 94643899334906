import React from "react";
import { Link } from "react-router-dom";
import './header.css';
import people from '../../assets/people.png'
import ride from '../../assets/ride.jpg'

const Header = ({ isLoggedIn }) => {
  return (
    <div className="gpt3__header section__padding gradient__bg" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">Let's Make Carpooling amazing and safe with SastiRide</h1>
        <p>SastiRide is an initiative to make carpooling easy and secure. It provides an opportunity to commuters to connect and have safe rides with any hassle.</p>
        
        
        <div className="gpt3__header-content__input">
        { isLoggedIn 
          ?  <Link to="/feed"><button>Check Rides</button></Link>
          :  <Link to="/login"><button>Get Started</button></Link>
        }
        </div>
        
        <div className="gpt3__header-content__people">
          <img src={people} alt="" />
          <p>100+ people have requested access so far</p>
        </div>
      </div>
      
      <div className="gpt3__header-image">
        <img src={ride} alt="ai" />
      </div>
    </div>
  );
}

export default Header;