import React, { useEffect, useState } from 'react';

import { Home, Footer, Feed, Profile } from "./containers";
import { Navbar, Login, Signup, Ride } from "./components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import axios from 'axios';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const getUser = async () => {
    try {
      await axios.get("/api/v1/login", {withCredentials: true});
      setIsLoggedIn(true);
    } catch (error) {
      console.log(error.response.data.message);
      setIsLoggedIn(false);
    }
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <BrowserRouter>
      <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout}></Navbar>
      <Routes className='App'>
        <Route path="/" element={<Home isLoggedIn={isLoggedIn} />}/>
        <Route path="/login" element={<Login isLoggedIn={isLoggedIn} onLogin={handleLogin} />}/>
        <Route path="/signup" element={<Signup isLoggedIn={isLoggedIn} onLogin={handleLogin} />}/>
        <Route path="/feed" element={<Feed isLoggedIn={isLoggedIn} />}/>
        <Route path="/profile" element={<Profile isLoggedIn={isLoggedIn} />}/>
        <Route path="/ride" element={<Ride isLoggedIn={isLoggedIn} />}/>
      </Routes>      
      <Footer></Footer>
    </BrowserRouter>
  );
};

export default App;