import React from "react";
import './field.css';

const Field = ( { heading, text } ) => {
  
  return (    
      <div className="field__content">
        <div className="field__content-div">
          <h3>{heading}: </h3>
          <p>{text}</p>
        </div>        
      </div>
  );
}

export default Field;